import { render, staticRenderFns } from "./CustomField.vue?vue&type=template&id=f979293c"
import script from "./CustomField.vue?vue&type=script&lang=js"
export * from "./CustomField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StyledSelect: require('/home/github/actions-runner/_work/plannr-nuxt/plannr-nuxt/components/StyledSelect.vue').default})
