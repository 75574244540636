import { render, staticRenderFns } from "./conversation-thread.vue?vue&type=template&id=37b4ae6a"
import script from "./conversation-thread.vue?vue&type=script&lang=js"
export * from "./conversation-thread.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/home/github/actions-runner/_work/plannr-nuxt/plannr-nuxt/components/Loader.vue').default,MessageSingle: require('/home/github/actions-runner/_work/plannr-nuxt/plannr-nuxt/components/MessageSingle.vue').default,ErrorBlock: require('/home/github/actions-runner/_work/plannr-nuxt/plannr-nuxt/components/ErrorBlock.vue').default,FileUploadClient: require('/home/github/actions-runner/_work/plannr-nuxt/plannr-nuxt/components/FileUploadClient.vue').default,RedactorInput: require('/home/github/actions-runner/_work/plannr-nuxt/plannr-nuxt/components/RedactorInput.vue').default,Avatar: require('/home/github/actions-runner/_work/plannr-nuxt/plannr-nuxt/components/Avatar.vue').default,AccountSelector: require('/home/github/actions-runner/_work/plannr-nuxt/plannr-nuxt/components/AccountSelector.vue').default})
