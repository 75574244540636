import { render, staticRenderFns } from "./MessageSingle.vue?vue&type=template&id=e4931ade"
import script from "./MessageSingle.vue?vue&type=script&lang=js"
export * from "./MessageSingle.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Avatar: require('/home/github/actions-runner/_work/plannr-nuxt/plannr-nuxt/components/Avatar.vue').default})
