import { render, staticRenderFns } from "./ResultItem.vue?vue&type=template&id=43abca52"
import script from "./ResultItem.vue?vue&type=script&lang=js"
export * from "./ResultItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchResultIcon: require('/home/github/actions-runner/_work/plannr-nuxt/plannr-nuxt/components/search/result-icon.vue').default})
